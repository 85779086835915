

import React, { Component,useRef ,useState,useEffect} from "react";

import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';


import { MdOutlineAccessTime } from "react-icons/md";


import {Controls,ScheduleEditor,ScheduleDisplay,daybyday_schedule,optimize_schedule,AssigntoMany} from "../Schedule/schedule_control.js"

function calculate_state(item){

  if(item.mode=="on"||item.mode=="off"){
    return item.mode
  }
  else{
    if (item.setpoint==1){
      return "on"
    }
    if (item.setpoint==0){
      return "off"
    }
  }
  return "?"


}

export default class Water extends Component {
    constructor(props) {
      super(props);
      this.state = {
        view:"home",
        water_mode:"on",
        water_state:"on",
        edit:false,
        current_schedule:"",
        show_shedule:false,
        new_auto_assign:"",
        new:false,
        schedule_assign:false,
        currentSchedule:{}
      }
    }
    changeWaterValue=(zone,value)=>{      
 
      if(zone.length==undefined){
        zone=[zone]
      }
      
     
       let d={
        cmd:"vars",
        type:"updateval_array",
        items:zone,
        value:value,
        var:"mode",
        check_schedule:true
      }
      this.props.message_callback(d)
      return


   /*   let d={
        cmd:"vars",
        type:"updateval",
        id:`zone_${zone}_mode`,
        value:value
      }
      
      this.props.message_callback(d)*/
     
    }
    handleSave=(schedule)=>{
      this.setState({currentSchedule:optimize_schedule(schedule)})



      if(this.state.new==true){
        if(this.state.current_schedule=="new"||this.state.current_schedule==""){
          alert("Schedule cannot be called new or blank")
          return 
        }


        if(this.props.current_schedules.includes(this.state.current_schedule)==true){
          alert("Shedule with same name already exists!")
          return 
        }
      
      

        

      }
      
      let d={
        cmd:"HEATING",
        type:"setSchedule",
        data:optimize_schedule(schedule),
        schedule:this.state.current_schedule
      }
      this.props.message_callback(d)
      if(this.state.new==true){
        this.props.message_callback({cmd:"schedule_assign",zones:[this.state.new_auto_assign],schedule:this.state.current_schedule})
        this.setState({new:false})
      }
      //need a list of schedules to check against


      console.log(optimize_schedule(schedule))
      this.setState({edit:false})
    }
    handleEditCancel=()=>{
      if (this.state.new==true){
        this.setState({show_shedule:false})
      }
      this.setState({edit:false})
    }


    edit_schedule = (name,id) => {

      let new_state=name=="new"
      
      //Blank/default schedule
      let edit_schedule={
        "period1": {
            "days": ["mon","tue","wed","thu","fri"],
            "times": [[420,1],[540,0],[960,1],[1320,0]]
        },
        "period2": {
            "days": ["sat","sun"],
            "times": [[420,1],[1320,0]]
        }
    }
      if(name in this.props.schedules){
        //We have
        edit_schedule=this.props.schedules[name]
      }else{
        name=""
      }
      
   



      this.setState({
        schedule_assign:false,
        current_schedule: name,
        currentSchedule:edit_schedule,
        show_shedule: true,
        new:new_state,
        new_auto_assign:id,
        edit:new_state
      }, () => {
        // After setting the state to show the schedule, scroll to the element
        const element = document.getElementById("edit_schedule");
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }
      });
    };

    clear_schedule=(id,schedule)=>{
      this.setState({show_shedule:false})
      let d={cmd:"schedule_unassign",zone:id,schedule:schedule}
      this.props.message_callback(d)
    }

    reAssignSchedule=(zone_update_list, schedule_id)=>{
      let d={cmd:"schedule_assign",zones:zone_update_list,schedule:schedule_id}
      this.props.message_callback(d)
      this.setState({schedule_assign:false})
    }

    assignSchedule=()=>{
      this.setState({schedule_assign:true})
    }

    render(){

      const zoneList = this.props.data.map(zone => {
        const { id, name,schedule } = zone;
        return { id, name ,schedule};
      });

    return ( <Container>
       

       
 <center>
        <Card style={{ maxWidth: '30rem' }}>
            <Card.Body>
            
            {this.props.data.map((entry,entry_index)=>
              <Card.Body>
              <Controls
              zone={entry.id}
              id={entry.id}
              stateText={entry.name}
              modeText="Water mode"
              currentState={entry.state?("On"):("Off")}
              currentMode={entry.mode}
              onValueChange={this.changeWaterValue}
              />
              {entry.schedule==""?(<Badge onClick={()=>this.edit_schedule("new",entry.id)}><MdOutlineAccessTime />Create new shedule</Badge>):
              (<>
              
              <Badge onClick={()=>this.edit_schedule(entry.schedule)}><MdOutlineAccessTime /> View {entry.schedule}</Badge>
              <Badge  bg="secondary" onClick={()=>this.clear_schedule(entry.id,entry.schedule)}><MdOutlineAccessTime /> Remove {entry.schedule}</Badge>
              
              </>)}

              
              </Card.Body>
            )}
            
            
      </Card.Body>
      </Card>
      </center>
<br/>

      <center id="edit_schedule">
      {this.state.show_shedule==true ? (<Card style={{ maxWidth: '30rem' }}>
            <Card.Body>
            <Card.Title>
               {this.state.new==true ?(
                      <Form.Control
                      type="text"
                      placeholder="Add Schedule Name"
                      value={this.state.current_schedule}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Remove characters that are not allowed in JSON object keys
                        const sanitizedValue = inputValue
                          .replaceAll(" ", "_") // Replace spaces with underscores
                          .replace(/[^a-zA-Z0-9_-]/g, ""); // Remove any characters other than letters, digits, underscore, and hyphen
                    
                        this.setState({ current_schedule: sanitizedValue });
                      }}
                    />
                ):(this.state.current_schedule)}       <Button variant="outline-secondary" onClick={()=>this.setState({show_shedule:false})} >Close</Button>
            </Card.Title>
            <Card.Subtitle>
              &nbsp;    
            </Card.Subtitle>           
            {this.state.edit==false ?(
            <Card.Text>
              <ScheduleDisplay heating_schedule={this.state.currentSchedule} days={["mon","tue","wed","thu","fri","sat","sun"]} mode="binary" />
              <Button variant="outline-primary" onClick={()=>{this.setState({edit:true})}}>Edit Schedule</Button>
              {' '}
              <Button variant="outline-primary" onClick={()=>{this.assignSchedule()}} >Apply to zones</Button>
              
            </Card.Text>
            ):(
            <Card.Text>
              <ScheduleEditor 
                  daybyday={daybyday_schedule(this.state.currentSchedule)}
                  onSave={this.handleSave}
                  cancel={this.handleEditCancel}
                  mode="binary"
                  label="Water"
               />
            </Card.Text>)}

            </Card.Body>
      </Card>):("")}
      </center>
      <AssigntoMany key={this.state.current_schedule+"_"+this.state.schedule_assign}
       schedule_id={this.state.current_schedule}
        open={this.state.schedule_assign}
         title={"Assign to "+this.state.current_schedule} 
         onValueChange={this.reAssignSchedule} 
         zoneList={zoneList} ></AssigntoMany>


  
        </Container>)
    }
}


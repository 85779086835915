
import React, { Component,useRef ,useState,useEffect} from "react";
import { CircularSlider, CircularSliderWithChildren } from "react-circular-slider-svg";

const colorLookupTable = [
    { temperature: 0, color: '#4c4cf0' },
    { temperature: 1, color: '#4f4fcc' },
    { temperature: 2, color: '#1919FF' },
    { temperature: 3, color: '#4f4fcc' },
    { temperature: 4, color: '#4f4fcc' },
    { temperature: 5, color: '#4040FF' },
    { temperature: 6, color: '#4D4DFF' },
    { temperature: 7, color: '#5A5AFF' },
    { temperature: 8, color: '#6767FF' },
    { temperature: 9, color: '#7474FF' },
    { temperature: 10, color: '#8181FF' },
    { temperature: 11, color: '#8E8EFF' },
    { temperature: 12, color: '#9B9BFF' },
    { temperature: 13, color: '#A8A8FF' },
    { temperature: 14, color: '#B5B5FF' },
    { temperature: 15, color: '#C2C2FF' },
    { temperature: 16, color: '#f5d4d4' },
    { temperature: 17, color: '#e5b3b3' },
    { temperature: 18, color: '#f3b1a5' },
    { temperature: 19, color: '#ffa797' },
    { temperature: 20, color: '#ffaeae' },
    { temperature: 21, color: '#ffa6a6' },
    { temperature: 22, color: '#ffa1a1' },
    { temperature: 23, color: '#FFA1A1' },
    { temperature: 24, color: '#FF8E8E' },
    { temperature: 25, color: '#FF7B7B' },
    { temperature: 26, color: '#FF6868' },
    { temperature: 27, color: '#FF5555' },
    { temperature: 28, color: '#FF4242' },
    { temperature: 29, color: '#FF2F2F' },
    { temperature: 30, color: '#FF1C1C' },
    { temperature: 31, color: '#FF1C1C' },
    { temperature: 32, color: '#FF1C1C' },
    { temperature: 33, color: '#FF1C1C' },
    { temperature: 34, color: '#FF1C1C' },
    { temperature: 35, color: '#FF1C1C' }
  ];

// Utility function to find the color range that matches the temperature
export function findColorRange(temperature) {
    const rangeIndex = colorLookupTable.findIndex(
      (range) => range.temperature >= temperature
    );
  
    if (rangeIndex === -1) {
      // Temperature exceeds the highest range, use the last color as fallback
      return colorLookupTable[colorLookupTable.length - 1];
    } else if (rangeIndex === 0) {
      // Temperature is below the lowest range, use the first color as fallback
      return colorLookupTable[0];
    } else {
      return colorLookupTable[rangeIndex];
    }
  }
  
  export function TemperatureInput({ minValue, maxValue,onValueChange ,initalValue }) {
    const [value1, setValue1] = useState(initalValue);
    const { color: gradientColor } = findColorRange(value1);
    const componentRef = useRef(null);
  
    useEffect(() => {
      const handleKeyDown = (event) => {
        
        if (
          document.activeElement.tagName !== 'INPUT' &&
          (event.key === 'ArrowUp' || event.key === 'ArrowDown')
        ) {
  
          if (event.key === 'ArrowUp' && value1 < maxValue) {
            event.preventDefault();
            setValue1((prevValue) => prevValue + 1);
            onValueChange(value1 + 1)
          } else if (event.key === 'ArrowDown' && value1 > minValue) {
            event.preventDefault();
            setValue1((prevValue) => prevValue - 1);
            onValueChange(value1 - 1)
          }
        }
      };
  
      document.addEventListener('keydown', handleKeyDown);
  
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [value1, minValue, maxValue]);
  
    return (
      <div
        style={{ display: 'inline-block' }}
        ref={componentRef}
        onWheel={(event) => {
          if (document.activeElement.tagName !== 'INPUT') {
          event.preventDefault();
          const delta = event.deltaY > 0 ? -1 : 1;
          if (value1 + delta <= maxValue && value1 + delta >= minValue) {
            setValue1((prevValue) => prevValue + delta);
            onValueChange(value1 + delta)
          }
        }
        }}
      >
        <CircularSliderWithChildren
          size={200}
          minValue={minValue}
          maxValue={maxValue}
          startAngle={40}
          endAngle={320}
          angleType={{
            direction: 'cw',
            axis: '-y',
          }}
          handle1={{
            value: value1,
            onChange: (v) => {
              setValue1(v);
              onValueChange(v);
            },
          }}
          outerShadow={false}
          arcColor={gradientColor}
          arcBackgroundColor="#aaa"
          onControlFinished={()=>
            onValueChange(value1)
          }
        >
          <div
            style={{
              textAlign: 'center',
              fontSize: '42px',
              userSelect: 'none',
              marginTop: '10px',
            }}
          >
            {parseFloat(value1).toFixed(0)}℃
          </div>
        </CircularSliderWithChildren>
      </div>
    );
  }



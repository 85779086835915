
import React, { Component } from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import HomeMenu from "./containers/home/home_overview.js"
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Heating from "./containers/heating/heating.js"
import Towels from "./containers/towel/towel.js"
import Water from "./containers/water/water.js"
import Cooling from "./containers/cooling/cooling.js"
import Settings from "./containers/settings/settings.js"
import Help from "./containers/settings/help.js"
import Spinner from 'react-bootstrap/Spinner';

import pako from 'pako';

import logo from "./CLOGO.png"
import { FaHandHoldingWater,FaTemperatureHigh, } from "react-icons/fa";
import { GiTowel } from "react-icons/gi";
import { MdSevereCold,MdChevronRight } from "react-icons/md";

import { GrUpdate } from "react-icons/gr";
//import Auth from '@aws-amplify/auth';
//import API from '@aws-amplify/api';
//import PubSub from "@aws-amplify/pubsub";
import "./App.css"
import {PubSub, Auth, API } from "aws-amplify";


let reconnectTimeout = null;

function updateZoneState(jsonMessage,zonesArray){

  const { data, state } = jsonMessage;
  const regex = /ZONE_(\d+)_(\w+)/;
  const match = data.match(regex);

  if (!match) {
    console.error("Invalid data format in the JSON message.");
    return zonesArray;
  }


  const zoneId = parseInt(match[1], 10);
  const fieldName = "state"

  for (let i = 0; i < zonesArray.length; i++) {
    if (zonesArray[i].id === zoneId) {
      zonesArray[i][fieldName] = state;
      break; // No need to continue once the matching zone is found and updated.
    }
  }

  return zonesArray;



}

function updateZoneData(jsonMessage, zonesArray) {
  const { data, value } = jsonMessage;
  const regex = /zone_(\d+)_(\w+)/;
  const match = data.match(regex);

  if (!match) {
    console.error("Invalid data format in the JSON message.");
    return zonesArray;
  }

  const zoneId = parseInt(match[1], 10);
  const fieldName = match[2];

  for (let i = 0; i < zonesArray.length; i++) {
    if (zonesArray[i].id === zoneId) {
      zonesArray[i][fieldName] = value;
      break; // No need to continue once the matching zone is found and updated.
    }
  }

  return zonesArray;
}

function HeatBreadcrumb(props){


  if(props.view=="home"){
    return ""
  }



  return (
    <Container>
    <Breadcrumb>
      <Breadcrumb.Item onClick={()=>props.callback("home")}>Home</Breadcrumb.Item>
      <Breadcrumb.Item active>{props.view}</Breadcrumb.Item>
    </Breadcrumb>
    </Container>
  );

}


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view:"home",
      isWebSocketOpen:false,
      zones:[],
      schedules:{},
      last_message:new Date(),
      cognitoId:"",
      initialLoad:true,
      initialLoadText:"",
      vars:{},
      isAuthenticted:false,
      isAuthenticating:true,
      username:"",
      gui_data:"",
      password:"",
      first_connect:true ,
      login_required:false,
      dialog_open:true,
      last_seen:0,
      connected:false,
      isLoading:false,
      login_feedback:""
    }
    
    this.socket = null; // Initialize socket to null
    this.connectWebSocket = this.connectWebSocket.bind(this);

  }

  remoteComsCheck=()=>{
    console.log("Remote coms check")
    if (this.state.initialLoad==true){
      this.send_it({cmd:"RemoteProg",type:"connect",id:this.state.cognitoId})
       let temptext=this.state.initialLoadText
      console.log(temptext)
      this.setState({"initialLoadText":temptext+"."})
      if(this.state.initialLoadText.includes("....................")==true||this.state.initialLoadText.startsWith("Abort")){
          console.log("this has been going a while, lets give up?")
          this.setState({"initialLoadText":"Aborted communication attempt"})
      }else{
        this.timer = setTimeout(() => this.remoteComsCheck(), 3000);
      }
   }else{
     console.log("We have had our inital update we need to keep the connection alive or the altiom will disconnect us!")
     this.send_it({cmd:"RemoteProg",type:"refresh",id:this.state.cognitoId})
     this.timer = setTimeout(() => this.remoteComsCheck(), 60000);
   }
  
  
   let currentTime=new Date().getTime()
   let timeDiff=currentTime-this.state.last_seen
   console.log(currentTime-this.state.last_seen)
  
  
   if(this.state.initialLoad==false){
   if(timeDiff>32000 && timeDiff<60000){
     //NotificationManager.warning('Not had a message in last 30 second!', 'Connection', 2000);
     console.log("Not had a message in 30s")
   }
  
   if(timeDiff>60000){
     //NotificationManager.error('Possible loss of communication to Altiom', 'Connection', 60000);
     this.setState({"initialLoadText":"Realtime datalink ended. Refresh page"})
     this.setState({initialLoad:true})
   }
  
  }
  
  
  
  
  
  }

  dologin = async event => {
    
    event.preventDefault()

    this.setState({ isLoading: true });
    try {
      const r1=await Auth.signIn(this.state.username, this.state.password);
      console.log(r1)
      const r=await this.userHasAuthenticated(true);
      console.log(r)
      this.setState({login_feedback:"Login successful"})
      this.reload_window()
      //  this.props.history.push("/");
    } catch (e) {
      this.setState({login_feedback:"Failed to login"})
      this.setState({ isLoading: false });
      if(e.code=="UserNotConfirmedException"){
        this.setState({showCode:true})
      }
    }
}

  userHasAuthenticated = async authenticated => {
   // console.log("User authenticated")
    let user = await Auth.currentAuthenticatedUser()
  
    this.setState({ isAuthenticated: authenticated });
    if(authenticated===true){
      this.setState({feedback_h1:"Authenticated"})
     // console.log("Attempting API")
      Auth.currentCredentials().then((info) => {
         // console.log(info)  
          const cognitoIdentityId = info.identityId;
          
          this.cognitoId=cognitoIdentityId
          this.setState({cognitoId:cognitoIdentityId})
        if(this.state.login_required==true){
          window.location.reload(true)
        }

      });
      return true
    }
    else
      return false



  }

  handleLogout = async event => {
    await Auth.signOut();
   
    this.setState({"login_required":true})
  }


  setpage=(page)=>{

    this.setState({view:page})


  }

  async componentDidMount() {

    window.send_it= this.send_it

    //this.connectWebSocket();
    //this.startMonitoringWebSocket();
    let gui_options=[]
    try{
    if (await Auth.currentSession()) {
      this.userHasAuthenticated(true);
      
      this.setState({feedback_h1:"Gathering info"})
      const data = await this.getInfo();
      this.setState({feedback_h1:"Loaded user profile"})

      for (let item in data.altiom){
        console.log(data.altiom[item])
        if("mode" in data.altiom[item]==true){
            console.log("mode")
            console.log(data.altiom[item])
            if(data.altiom[item].id=="8e62719c-7c21-8c4a-e42a-8a8d0cde6f5b"){
              console.log("MATCH!")
              data.altiom[item].id="ALT-0004F31116FC" //real
              //data.altiom[item].id="ALT-0004F3111680" //DEV one
              
              gui_options.push(data.altiom[item])
            }
            //Its an individual altiom
            /*if("controlight" in data.altiom[item].data){
                if(data.altiom[item].data.controlight==true){
                  gui_options.push(data.altiom[item])

                }
            }*/
            console.log(data.altiom[item]["mode"]=="sensio")
              //Gui found
        }
      }
    }
  }
  catch(e) {
    console.log(e)
    if (e !== 'No current user') {
      console.log(e)
      this.setState({"login_required":true})
    }else{

      this.setState({"login_required":true})


    }

}
console.log(gui_options)
if(gui_options.length==1){
  //We have one option!
  this.setState({gui_data:gui_options[0]})
  this.wsConnect(gui_options[0].id)
}


  }

  getInfo() {
    return API.get("AltiomData", "/welcome");
  }
  componentWillUnmount() {
    // Close the WebSocket connection and clear the socket instance when the component unmounts
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }



    
  }

  zone_data=(zones)=>{
    console.log(zones)
    this.setState({zones:zones})
  }

  processWSdata=function (data){
    console.log("PROCESS WS")
    this.setState({"last_seen":new Date().getTime()})
    
    
    //data=JSON.parse(data)





   


    this.setState({last_message:new Date()})
    if(data.cmd=="zonedata"){
      console.log("ZONE DATA")
      this.zone_data(data.data)
    }

    if(data.cmd=="event"){
      if(data.data.startsWith("ZONE")){
        let zone_update=updateZoneState(data,this.state.zones)
        this.setState({zone_data:zone_update})
      }
    }


     if(data.cmd=="varchange"){
      if(data.data.startsWith("zone")){
      let zone_update=updateZoneData(data,this.state.zones)
      this.setState({zone_data:zone_update})
      }
      if(data.data.startsWith("temperature")||data.data.startsWith("cooling")){
        
        let vars_update=this.state.vars
        console.log("var update")
        //console.log(vars_update)
        console.log(data)
        let state_update={}
          state_update[data.data]=data.value
          console.log(state_update)
          this.setState(state_update)
        if(vars_update[data.data]){
          console.log("Var being updated")
          console.log(data.data)
          vars_update[data.data]=data.value
          this.setState({vars:vars_update})
          
        }
        
        
      }

      
     }

    if(data.cmd && data.type){
      
      
      if(data.cmd=="HEATING" && data.type== 'scheduleData'){
        this.setState({schedules:data.data})
      }

      if(data.cmd=="update" && data.type=="all"){
        console.log("extact zone info")
        this.setState({initialLoad:false})
        this.setState({vars:data.data.vars})
        this.setState({...data.data})
        this.zone_data(data.data.zones)
        this.send_it({cmd:"HEATING",type:"getSchedules"})
      }


    }

    if(Object.keys(this.state.schedules).length==0){
      console.log("No schedules yet?")
    //  this.send_it({cmd:"HEATING",type:"getSchedules"})
    }
    
  }.bind(this)


  startMonitoringWebSocket() {
    // Clear any existing timeout
    clearTimeout(reconnectTimeout);


    let currentTime = new Date();
    let timeElapsed = currentTime - this.state.last_message; // Time elapsed in milliseconds
    let secondsElapsed = Math.floor(timeElapsed / 1000);
    if (secondsElapsed > 60) {
      
      console.log("More than 60 seconds have passed since the last message.");
      window.location.reload(true)
    }
  
    // Check the state of isWebSocketOpen after 10 seconds
    reconnectTimeout = setTimeout(() => {
      
      if (!this.state.isWebSocketOpen) {
        console.log("try connect")
        // If WebSocket is not open after 10 seconds, try to reconnect
        this.connectWebSocket();
      }
      this.startMonitoringWebSocket();
    }, 10000); // 10 seconds (adjust the timeout duration as needed)
  }
  send=(msg)=>{

    if(this.cognitoId.length!=0){
      
        API.post("AltiomData", "/altiom/"+this.state.gui_data.id,{body:JSON.parse(msg)}).then(response => {
        }).catch(error => {
          console.log(error.response)
        });
      }else{  
        try{
          this.socket.send(msg)
        }catch(err){}
      }
  }

  wsConnect=(id)=>{
    console.log("Start subsciption")
    console.log(id+'/remote/'+this.state.cognitoId)
    this.subscription=PubSub.subscribe(id+'/remote/'+this.state.cognitoId).subscribe({
      next: data => {
        console.log("remote coms")
        console.log(data.value)
        if(data.value.zlib){
           // Decode Base64 data
              console.log("Compressed data!")
              let compressedData = window.atob(data.value.zlib);
 // Convert the decoded data to Uint8Array
              const uint8ArrayData = new Uint8Array(compressedData.length);
              for (let i = 0; i < compressedData.length; i++) {
                uint8ArrayData[i] = compressedData.charCodeAt(i);
              }

                // Decompress zlib compressed data
              const decompressedData = pako.inflate(uint8ArrayData, { to: 'string' });
              console.log(decompressedData)
              data.value=JSON.parse(decompressedData)

            
        }
        
        if(this.state.first_connect==true){
          this.setState({first_connect:false})
          console.log("WEBSOCKET INITIAL FIRST MESSAGE")
          this.setState({"connected":true})
          this.send_it({cmd:"HEATING",type:"getSchedules"})
         // this.resend_offline(this.state.offline_message)

         // this.setState({offline_message:[]})
        }
        this.processWSdata(data.value)

      },
      error: error => this.subscibeError(error),
      close: () => console.log('Done'),
      complete:()=> console.log("WS complete??")
  });
  let connect_str={cmd:"RemoteProg",type:"connect",id:this.state.cognitoId}
  console.log(connect_str)
  this.send_it(connect_str)
    this.timer = setTimeout(() => this.remoteComsCheck(), 5000);

  }



  connectWebSocket() {
    // Check if a WebSocket instance already exists and close it before creating a new one
    if (this.socket) {
      console.log("close socket")
      this.socket.close();
      this.socket = null;
    }
    let url=window.location.hostname
    if(url=="localhost"){
      url="192.168.4.83"
    }
    // Create a new WebSocket instance and set up event listeners
    this.socket = new WebSocket(`ws://${url}/ws`);
    // Replace "YOUR_WEBSOCKET_SERVER_URL" with the actual WebSocket server URL.
    
    
    this.socket.addEventListener("open", () => {
      this.setState({ isWebSocketOpen: true });
      this.send_it({cmd:"HEATING",type:"getSchedules"})
      this.socket.addEventListener("close", () => {
        this.setState({ isWebSocketOpen: false });
        console.error("the socket has closed?")
        // Attempt to reconnect after a certain interval
        
      });
    })
    
    this.socket.addEventListener("message", (event) => {
      // Update the state with the received data
      
      this.processWSdata(event.data);
      
    });
    // ... Other event listeners and message handling ...

    // Set up reconnection logic in the close event listener
   
  }
  send_it=(msg)=>{
    console.log("Sending message:"+JSON.stringify(msg))
    if(this.state.cognitoId.length!=0){
        API.post("AltiomData", "/altiom/"+this.state.gui_data.id,{body:msg}).then(response => {
          console.log(response)
        }).catch(error => {
          console.log(error.response)
        });
      }else{
    
        try{
          this.socket.send(JSON.stringify(msg))
        }catch(err){}
    

  }
  }



  render(){


    if(this.state.login_required==true){
    
    return (
    
      
      <Container>
      <Card >   
      <br></br>
      <center><Card.Title>Login</Card.Title></center>
<Card.Body>

<Form onSubmit={(e)=>this.dologin(e)}>
      <Form.Group className="mb-3" controlId="formGroupEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={(e)=>this.setState({"username":e.target.value})} value={this.state.username} name="username" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formGroupPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password" onChange={(e)=>this.setState({"password":e.target.value})} value={this.state.password} name="password" />
      </Form.Group>
      <Button disabled={this.state.isLoading}  onClick={this.dologin} type="submit" variant="info" color="secondary">
        Login
      </Button>
    </Form>


    <center>{this.state.login_feedback}</center>

      </Card.Body>
      </Card>
      </Container>
      )

  }

    const distinctSchedules = [...new Set(this.state.zones.map((entry) => entry.schedule))];

    return (<div>

<Modal show={!this.state.connected} >
        <Modal.Header >
          <Modal.Title><center>Connection</center></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
          <p>establishing connection</p>
          <Spinner animation="border" />
      </center>


        </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>
      
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home"><img src={logo} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Button onClick={()=>window.location.reload(true)}><GrUpdate/></Button>
            
            <NavDropdown title="Quick access" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={()=>this.setpage("Hot water")}>Hot Water</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setpage("Towel rails")}>Towel Rails</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setpage("Heating")}>Heating</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>this.setpage("Cooling")}>Cooling</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={()=>this.setpage("home")}>
                Overview
              </NavDropdown.Item>
              {/*<NavDropdown.Item onClick={()=>this.setpage("help")}>
                Help
    </NavDropdown.Item>*/}
            </NavDropdown>
          </Nav>
          <Nav>
            
            <Nav.Link eventKey={2} onClick={()=>this.handleLogout()}>
           Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


<HeatBreadcrumb callback={this.setpage} view={this.state.view} ></HeatBreadcrumb>
      {this.state.view=="home"        ?(<HomeMenu callback={this.setpage} />):("")}
      {this.state.view=="Hot water"   ?(<Water    callback={this.setpage} key="water"   data={this.state.zones.filter((entry) => entry.type === "water")}   message_callback={this.send_it} current_schedules={Object.keys(this.state.schedules)} schedules={this.state.schedules} vars={this.state.vars} />):("")}
      {this.state.view=="Towel rails" ?(<Towels   callback={this.setpage} key="towels"  data={this.state.zones.filter((entry) => entry.type === "towel")}  message_callback={this.send_it} current_schedules={Object.keys(this.state.schedules)} schedules={this.state.schedules}  vars={this.state.vars} />):("")}
      {this.state.view=="Heating"     ?(<Heating  callback={this.setpage} key="heating" data={this.state.zones.filter((entry) => entry.type === "heating")} message_callback={this.send_it} current_schedules={Object.keys(this.state.schedules)} schedules={this.state.schedules} vars={this.state.vars} state={this.state}/>):("")}
      {this.state.view=="Cooling"     ?(<Cooling  callback={this.setpage} key="cooling" data={this.state.zones.filter((entry) => entry.type === "cooling")} message_callback={this.send_it} current_schedules={Object.keys(this.state.schedules)} schedules={this.state.schedules} vars={this.state.vars} state={this.state}/>):("")}
      {this.state.view=="Help"        ?(<Help     callback={this.setpage} key="help"     />):("")}
      {this.state.view=="Settings"    ?(<Settings callback={this.setpage} key="settings" />):("")}
    </div>)


  }

}





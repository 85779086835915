

 
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { FaHandHoldingWater,FaTemperatureHigh, } from "react-icons/fa";
import { GiTowel } from "react-icons/gi";
import { MdSevereCold,MdChevronRight } from "react-icons/md";

export default function Help(props){


    return ( <Container>
       
        Help
  
        </Container>)
}
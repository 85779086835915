
import React, { Component,useRef ,useState,useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


import { MdOutlineAccessTime,MdAddCircleOutline,MdOutlineDeleteOutline,MdOutlineModeEditOutline} from "react-icons/md";
import { IoDuplicateOutline ,IoWater} from "react-icons/io5";
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {OnOFFToggle} from "./OnOffControl.js"
import {TemperatureInput,findColorRange} from "./TemperatureInput.js"
import { FaCheck } from "react-icons/fa";
import InputGroup from 'react-bootstrap/InputGroup';


import { FaFan,FaPowerOff,FaTemperatureLow ,FaSnowflake,FaSun} from "react-icons/fa";


export const AssigntoMany =({schedule_id,title,onValueChange,open,zoneList=[],})=>{
  const getInitialZoneArray = (scheduleName) => {
    return zoneList.filter(item => item.schedule === scheduleName).map(item => item.id);
  };
  const [modalIsOpen, setModalIsOpen] = useState(open);
  const [zoneArray,setzoneArray]= useState(getInitialZoneArray(schedule_id));
  
 
  const handleMulti=()=>{
     onValueChange(zoneArray, schedule_id);
  }

  const handleCheckboxChange = (zone_id) => {
    if (zoneArray.includes(zone_id)) {
      // If the day is already in the array, remove it
      setzoneArray(zoneArray.filter((d) => d !== zone_id));
    } else {
      // If the day is not in the array, add it
      setzoneArray([...zoneArray, zone_id]);
    }
  };


  const closeCopyModal = () => {
    //setModalIsOpen(false);
    onValueChange([],"")
  };

  const handleClearSelection = () => {
    setzoneArray([]);
  };


  const handleSelectAll = () => {
    const allZones = zoneList.map((zone) => zone.id);
    setzoneArray(allZones);
  };


  const handleInvertSelection = () => {
    const invertedSelection = zoneList
      .filter((zone) => !zoneArray.includes(zone.id))
      .map((zone) => zone.id);
      setzoneArray(invertedSelection);
  };




  return (
    <>
       {zoneList.length>0 ?(<Modal show={modalIsOpen} onHide={closeCopyModal}> 
        <Modal.Header closeButton>
          {title}
          </Modal.Header>
          <Modal.Body>
          <Button variant="outline-primary" onClick={handleClearSelection}>
      Clear Selection
    </Button>{' '}
    
    <Button variant="outline-danger" onClick={handleSelectAll}>
      Select All
    </Button>{' '}
    
    <Button variant="outline-warning" onClick={handleInvertSelection}>
      Invert Selection
    </Button>

          {zoneList.map((zone,zone_index) => (
      <Row  key={zone.id}>
      <Col>
      <Form.Check
        key={zone.id}
        type="checkbox"
        id={`${zone.id}-checkbox`}
        label={zone.name}
        value={zone.id}
        checked={zoneArray.includes(zone.id)}
        onChange={() => handleCheckboxChange(zone.id)}
      />
       </Col>
       <Col>
   
      </Col>
      </Row>

    ))}
  
  <hr/>
  

  
  
  
          </Modal.Body>
    
          <Modal.Footer>
          <Button onClick={()=>handleMulti()}>Set</Button>
          <Button onClick={()=>closeCopyModal()} >Cancel</Button>
          </Modal.Footer>
        </Modal>):("")}
      
    </>
  );
};

function displaySetpoint(value){
  value=parseFloat(value).toFixed(0)
  if(isNaN(value)){
    return "?"
  }
  return value
}


function displayTemp(value){
  value=parseFloat(value).toFixed(0)
  if(isNaN(value)){
    return 0
  }
  return value
}

export const Controls = ({zone,id, stateText, modeText, currentState, currentMode, onValueChange,zoneList=[],state,setPoint=false ,zonedata,vars}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tempMode, settempMode] = useState("");
    const [tempOveride, settempOveride] = useState("");
    const [zoneArray,setzoneArray]= useState([]);
    const [editedValue, setEditedValue] = useState(displayTemp(setPoint));
    const [modalTempOpen, setModalTempOpen] = useState(false);



    let temperature_room=""
    if(zonedata){
      if (zonedata.tempmap){
        if(vars[zonedata.tempmap]){
          temperature_room=vars[zonedata.tempmap]+"℃"
        }
        
      }
    }
    let temperature_floor=""
    if(zonedata){
      if (zonedata.tempfloor){
        if(vars[zonedata.tempmap]){
          temperature_floor=vars[zonedata.tempfloor]+"℃"
        }
        
      }
    }

    let interlock=""
    
  if(zonedata){
    if (zonedata.interlock){
 
      let mod_addr=`cooling_${zonedata.interlock.toString().padStart(2, '0')}_reg`
      
      let reg03=state[`${mod_addr}03`]


      switch (reg03) {
        case 0:
          reg03 = "auto";
          break;
        case 1:
          reg03 = "heat";
          break;
        case 2:
          reg03 = "fan";
          break;
        case 3:
          reg03 = "cool";
          break;
        case 4:
          reg03 = "dry";
          break;
        default:
          reg03 = "unknown";
          break;
      }


 
      let reg05=state[`${mod_addr}05`] === 1 ? "on" : "off";
      if(reg03=="cool" && reg05=="on"){
        interlock="AC interlock active"
      }
   
    }
  }




    const handleValueChange = (zone, value) => {
        // Perform additional logic here before calling onValueChange
        // ...
        if(zoneList.length>0){
            settempMode(value);
            setModalIsOpen(true);
            return
        }
        
        // Call onValueChange with the updated zone and value
        onValueChange(id, value);
      };

      const handleTempSet =(zone ,value,mode) =>{
        console.log("handleTempChange")
        console.log(zoneList.length)
        //Change setPoint!!!!
            if(zoneList.length>0){
              console.log("Multi")
              setEditedValue(parseFloat(value).toFixed(0));
              settempOveride(mode)
              settempMode(parseInt(value));
              setModalTempOpen(false);
              setModalIsOpen(true);
              
              return
          }
              
          onValueChange(zone,parseInt(value),mode)
          setModalTempOpen(false);
      }




      const closeCopyModal = () => {
        setModalIsOpen(false);
      //  
        // Reset editing field
      };

     const handleMulti=()=>{
            setModalIsOpen(false);
            onValueChange(zoneArray, tempMode,tempOveride);

      }

        
    const handleCheckboxChange = (zone_id) => {
        if (zoneArray.includes(zone_id)) {
          // If the day is already in the array, remove it
          setzoneArray(zoneArray.filter((d) => d !== zone_id));
        } else {
          // If the day is not in the array, add it
          setzoneArray([...zoneArray, zone_id]);
        }
      };


      const handleClearSelection = () => {
        setzoneArray([]);
      };


      const handleSelectAll = () => {
        const allZones = zoneList.map((zone) => zone.id);
        setzoneArray(allZones);
      };


      const handleInvertSelection = () => {
        const invertedSelection = zoneList
          .filter((zone) => !zoneArray.includes(zone.id))
          .map((zone) => zone.id);
          setzoneArray(invertedSelection);
      };


      const handletempchange =() =>{
        setModalTempOpen(true);
      }

      const closeTempModal = () => {
        setModalTempOpen(false);
      //  
        // Reset editing field
      };

      const handleTempValueChange = (e) => {
        setEditedValue(parseFloat(e).toFixed(0));
      };

    return (
    <>
        <div className="zone_title">{stateText} {' '}</div>

      <div className="zonetags">
      {zoneList.length>0 ? (""):(
        <>
        <Badge bg="info">{currentState}</Badge>
        {temperature_room.length!=0?(<Badge bg="info" title={zonedata.tempmap}>Room:{temperature_room}</Badge>):("")}
        {temperature_floor.length!=0?(<Badge bg="info" title={zonedata.tempfloor}>Floor:{temperature_floor}</Badge>):("")}

        {interlock.length!=0?(<Badge bg="warning" title={zonedata.tempfloor}>{interlock}</Badge>):("")}
        </>
        
        
        )}

      </div>
      
      <div className="controls">
      
      
      <ButtonGroup className="mb-2" id={id}>

        { (typeof setPoint!=='number' && setPoint.toString()=="false" )?
        (<ToggleButton
          key={id+"mode-on"}
          id={id+"radio-ON"}
          type="radio"
          variant="secondary"
          name={id+"radio"}
          value="on"
          checked={"on" === currentMode}
                      onChange={(e) => handleValueChange(zone, e.currentTarget.value)}
        >
          On
        </ToggleButton>):(
        <ToggleButton  checked={"on" === currentMode}  
        value="on" variant="secondary"
        type="radio"
         onClick={() =>handletempchange(zone)} >{displaySetpoint(setPoint)}℃</ToggleButton>)}

        <ToggleButton
         title="zone follows schedule"
          key="mode-timer"
          id={id+"radio-Timer"}
          type="radio"
          variant="secondary"
          name={id+"radio"}
          value="timer"
          checked={"timer" === currentMode}
                      onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

        >
          <MdOutlineAccessTime /> Timer
        </ToggleButton>

        <ToggleButton
        title="zone is disabled"
          key="mode-off"
          id={id+"radio-OFF"}
          type="radio"
          variant="secondary"
          name={id+"radio"}
          value="off"
          checked={"off" === currentMode}
                      onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

        >
          Off
        </ToggleButton>
      </ButtonGroup>



      <Modal show={modalTempOpen} onHide={closeTempModal}> 
        <Modal.Header closeButton>
          Alter setpoint {tempMode}
          </Modal.Header>
          <Modal.Body>
        <center><TemperatureInput minValue={5} maxValue={35} onValueChange={handleTempValueChange} initalValue={editedValue}/>  </center>
  
  <p><b>Set Forever</b> <i>The schedule is ignored and the setpoint determines the heating state</i></p>

  <p><b>Until overridden</b> <i>The setpoint is altered to new value until the next schedule temperature takes effect</i></p>
 
          </Modal.Body>   
          
          <Modal.Footer>
          <Button onClick={()=>handleTempSet(zone,editedValue,"on")}>Set Forever</Button>
          <Button onClick={()=>handleTempSet(zone,editedValue,"timer")}>Until Overridden</Button>
          <Button onClick={()=>closeTempModal()} >Cancel</Button>
          </Modal.Footer>
        </Modal>


      {zoneList.length>0 ?(<Modal show={modalIsOpen} onHide={closeCopyModal}> 
        <Modal.Header closeButton>
          Set zones to {tempMode}
          </Modal.Header>
          <Modal.Body>


          <Button variant="outline-secondary" onClick={handleClearSelection}>
      Clear Selection
    </Button>{' '}
    
    <Button variant="outline-danger" onClick={handleSelectAll}>
      Select All
    </Button>{' '}
    
    <Button variant="outline-warning" onClick={handleInvertSelection}>
      Invert Selection
    </Button>

          {zoneList.map((zone,zone_index) => (
      <Row key={zone.id}>
      <Col>
      <Form.Check
        key={zone.id}
        type="checkbox"
        id={`${zone.id}-checkbox`}
        label={zone.name}
        value={zone.id}
        checked={zoneArray.includes(zone.id)}
        onChange={() => handleCheckboxChange(zone.id)}
      />
       </Col>
       <Col>   
      </Col>
      </Row>
    ))}
  
  <hr/>

          </Modal.Body>   
          <Modal.Footer>
          <Button onClick={()=>handleMulti()}>Set</Button>
          <Button onClick={()=>closeCopyModal()} >Cancel</Button>
          </Modal.Footer>
        </Modal>):("")}
      </div>
    </>
  );
};

export const ControlsCool = ({zone,id, stateText, modeText, currentState, currentMode, onValueChange,zoneList=[],setPoint=false ,zonedata,vars,state,onFanChange,onModeChange}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tempMode, settempMode] = useState("");
  const [tempOveride, settempOveride] = useState("");
  const [zoneArray,setzoneArray]= useState([]);
  const [editedValue, setEditedValue] = useState(displayTemp(setPoint));
  const [modalTempOpen, setModalTempOpen] = useState(false);
  const [currentFan, setCurrentFan] = useState("fan1");
  
  let reg01=0
  let reg02=0
  let reg03=0
  let reg04=0
  let reg05=0



  if(zonedata){
    if (zonedata.cooladdr){
      let mod_addr=`cooling_${zonedata.cooladdr.toString().padStart(2, '0')}_reg`
      reg01=state[`${mod_addr}01`]  //setpoint
      reg02="fan"+vars[`${mod_addr}02`] //fan speed
      reg03=state[`${mod_addr}03`]


      switch (reg03) {
        case 0:
          reg03 = "auto";
          break;
        case 1:
          reg03 = "heat";
          break;
        case 2:
          reg03 = "fan";
          break;
        case 3:
          reg03 = "cool";
          break;
        case 4:
          reg03 = "dry";
          break;
        default:
          reg03 = "unknown";
          break;
      }


      reg04=state[`${mod_addr}04`]
      reg05=state[`${mod_addr}05`] === 1 ? "on" : "off";
   
    }
  }


  

  const handleFanChange = (zone, value) => {
    // Perform additional logic here before calling onValueChange
    // ...
    if(zoneList.length>0){
        settempMode(value);
        setModalIsOpen(true);
        return
    }
    
    // Call onValueChange with the updated zone and value
    onValueChange(id, value);
  };


  const handleValueChange = (zone, value) => {
      // Perform additional logic here before calling onValueChange
      // ...
      if(zoneList.length>0){
          settempMode(value);
          setModalIsOpen(true);
          return
      }
      
      // Call onValueChange with the updated zone and value
      onValueChange(id, value);
    };

    const handleTempSet =(zone ,value,mode) =>{
      console.log("handleTempChange")
      console.log(zoneList.length)
      //Change setPoint!!!!
          if(zoneList.length>0){
            console.log("Multi")
            setEditedValue(parseFloat(value).toFixed(0));
            settempOveride(mode)
            settempMode(parseInt(value));
            setModalTempOpen(false);
            setModalIsOpen(true);
            
            return
        }
            
        onValueChange(zone,parseInt(value),mode)
        setModalTempOpen(false);
    }


    




    const closeCopyModal = () => {
      setModalIsOpen(false);
    //  
      // Reset editing field
    };

   const handleMulti=()=>{
          setModalIsOpen(false);
          onValueChange(zoneArray, tempMode,tempOveride);

    }

      
  const handleCheckboxChange = (zone_id) => {
      if (zoneArray.includes(zone_id)) {
        // If the day is already in the array, remove it
        setzoneArray(zoneArray.filter((d) => d !== zone_id));
      } else {
        // If the day is not in the array, add it
        setzoneArray([...zoneArray, zone_id]);
      }
    };


    const handleClearSelection = () => {
      setzoneArray([]);
    };


    const handleSelectAll = () => {
      const allZones = zoneList.map((zone) => zone.id);
      setzoneArray(allZones);
    };


    const handleInvertSelection = () => {
      const invertedSelection = zoneList
        .filter((zone) => !zoneArray.includes(zone.id))
        .map((zone) => zone.id);
        setzoneArray(invertedSelection);
    };


    const handletempchange =() =>{
      setModalTempOpen(true);
    }

    const closeTempModal = () => {
      setModalTempOpen(false);
    //  
      // Reset editing field
    };

    const handleTempValueChange = (e) => {
      setEditedValue(parseFloat(e).toFixed(0));
    };

  return (
  <>
      <div className="zone_title">{stateText} {' '}</div>


    


    <ButtonToolbar className="coolbar">

    <ButtonGroup className="me-2" >
    <InputGroup>
          <InputGroup.Text id="btnGroupAddon"><FaPowerOff/></InputGroup.Text> 
<ToggleButton
 title="turn on the zone"
  key={id+"mode-on"}
  id={id+"radio-on"}
  type="radio"
  variant="secondary"
  name={id+"radio5"}
  value="on"
  checked={"on" === reg05}
              onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

>
  On
</ToggleButton>

<ToggleButton
title="turn off the zone"
  key={id+"mode-off"}
  id={id+"radio-off"}
  type="radio"
  variant="secondary"
  name={id+"radio5"}
  value="off"
  checked={"off" === reg05}
              onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

>
  Off
</ToggleButton>
 </InputGroup>
</ButtonGroup>


<ButtonGroup className="me-2" >
<InputGroup>
          <InputGroup.Text id="btnGroupAddon"><FaFan/></InputGroup.Text>
          <ToggleButton
       title="fan speed 1"
        key={id+"mode-fan1"}
        id={id+"radio-fanspeed1"}
        type="radio"
        variant="secondary"
        name={id+"radiof"}
        value="fan1"
        checked={"fan1" === reg02}
                    onChange={(e) => handleFanChange(zone, e.currentTarget.value)}

      >
        1
      </ToggleButton>
      <ToggleButton
       title="fan speed 2"
        key={id+"mode-fan2"}
        id={id+"radio-fanspeed2"}
        type="radio"
        variant="secondary"
        name={id+"radiof"}
        value="fan2"
        checked={"fan2" === reg02}
                    onChange={(e) => handleFanChange(zone, e.currentTarget.value)}

      >
        2
      </ToggleButton>
      <ToggleButton
       title="fan speed 3"
        key={id+"mode-fan3"}
        id={id+"radio-fanspeed3"}
        type="radio"
        variant="secondary"
        name={id+"radiof"}
        value="fan3"
        checked={"fan3" === reg02}
                    onChange={(e) => handleFanChange(zone, e.currentTarget.value)}

      >
        3
      </ToggleButton>
</InputGroup>

     


    </ButtonGroup>


    </ButtonToolbar >

<div className="padgap"></div>
    <ButtonToolbar className="coolbar">
    
    <ButtonGroup className="me-2"  >
    <InputGroup>
          <InputGroup.Text id="btnGroupAddon"><FaTemperatureLow/></InputGroup.Text> 
          
<Button  checked={"on" === currentMode}  
      value="on" variant="secondary"
      type="radio"
       onClick={() =>handletempchange(zone)} >{displaySetpoint(reg01)}℃</Button>

</InputGroup>
</ButtonGroup>

{' '}{' '}



    <ButtonGroup className="me-2" >

<ToggleButton
 title="zone heat mode"
  key={id+"mode-heat"}
  id={id+"radio-heat"}
  type="radio"
  variant="secondary"
  name={id+"radioheat"}
  value="heat"
  checked={"heat" === reg03}
              onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

>
<FaSun/>{' '}Heat
</ToggleButton>

<ToggleButton
title="zone cool mode"
  key={id+"mode-cool"}
  id={id+"radio-cool"}
  type="radio"
  variant="secondary"
  name={id+"cool"}
  value="cool"
  checked={"cool" === reg03}
              onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

>
  <FaSnowflake/>{' '}Cool
</ToggleButton>
<ToggleButton
title="zone fan mode"
  key={id+"mode-fan"}
  id={id+"radio-fan"}
  type="radio"
  variant="secondary"
  name={id+"fan"}
  value="fan"
  checked={"fan" === reg03}
              onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

>
<FaFan/>{' '}Fan
</ToggleButton>

<ToggleButton
title="zone dry mode"
  key={id+"mode-dry"}
  id={id+"radio-dry"}
  type="radio"
  variant="secondary"
  name={id+"dry"}
  value="dry"
  checked={"dry" === reg03}
              onChange={(e) => handleValueChange(zone, e.currentTarget.value)}

>
 <IoWater/>{' '}Dry
</ToggleButton>
</ButtonGroup>


    </ButtonToolbar>





    <Modal show={modalTempOpen} onHide={closeTempModal}> 
      <Modal.Header closeButton>
        Alter setpoint {tempMode}
        </Modal.Header>
        <Modal.Body>
      <center><TemperatureInput minValue={17} maxValue={32} onValueChange={handleTempValueChange} initalValue={editedValue}/>  </center>


        </Modal.Body>   
        
        <Modal.Footer>
        <Button onClick={()=>handleTempSet(zone,editedValue,"on")}>Set </Button>
        <Button onClick={()=>closeTempModal()} >Cancel</Button>
        </Modal.Footer>
      </Modal>


    {zoneList.length>0 ?(<Modal show={modalIsOpen} onHide={closeCopyModal}> 
      <Modal.Header closeButton>
        Set zones to {tempMode}
        </Modal.Header>
        <Modal.Body>


        <Button variant="outline-secondary" onClick={handleClearSelection}>
    Clear Selection
  </Button>{' '}
  
  <Button variant="outline-danger" onClick={handleSelectAll}>
    Select All
  </Button>{' '}
  
  <Button variant="outline-warning" onClick={handleInvertSelection}>
    Invert Selection
  </Button>

        {zoneList.map((zone,zone_index) => (
    <Row key={zone.id}>
    <Col>
    <Form.Check
      key={zone.id}
      type="checkbox"
      id={`${zone.id}-checkbox`}
      label={zone.name}
      value={zone.id}
      checked={zoneArray.includes(zone.id)}
      onChange={() => handleCheckboxChange(zone.id)}
    />
     </Col>
     <Col>   
    </Col>
    </Row>
  ))}

<hr/>

        </Modal.Body>   
        <Modal.Footer>
        <Button onClick={()=>handleMulti()}>Set</Button>
        <Button onClick={()=>closeCopyModal()} >Cancel</Button>
        </Modal.Footer>
      </Modal>):("")}
    
  </>
);
};

//Convert from mins into day into HH:MM for interface
function mins2hhmm(minutes) {
    var m = minutes % 60;
    var h = (minutes - m) / 60;
    var t = ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2);
    return t;
  }

  
//Take the Altiom schedule format and convert data from array to Object
// {date:HH:MM,value:TT}
function formatSchedule_human(data) {
var tmp = [];
for (var i = 0; i < data.length; i++) {
    var t = mins2hhmm(data[i][0]);
    var v = data[i][1];
    tmp.push({ date: t, value: v });
}
return tmp;
}


// reverse function for taking HH:MM data and fiving mins into day
function formatSchedule_tomins(data){
  
    const minutesArray = data.map(item => {
      const [hours, minutes] = item.date.split(':');
      const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
      return [ totalMinutes,  item.value ]
    });
  
    return minutesArray;
}


/* 
 Day by day schedule
 this expands a schedule out into individual days
 this allows for each day to be modified independantly.
 they can then be combined and optimised once the edit is complete.
*/



  export function daybyday_schedule(schedule){
    //convert the optimised data of a schedule into one that is on a per day basis so that each day can be edited independently
    const day_schedule={
      "mon":{days:["mon"], times:[]},
      "tue":{days:["tue"], times:[]},
      "wed":{days:["wed"], times:[]},
      "thu":{days:["thu"], times:[]},
      "fri":{days:["fri"], times:[]},
      "sat":{days:["sat"], times:[]},
      "sun":{days:["sun"], times:[]}
    }
  
    for (var period in schedule) {
      if (schedule.hasOwnProperty(period)) {
        var periodObj = schedule[period];
        periodObj.days.forEach(day => {
          if (day_schedule.hasOwnProperty(day)) {
            day_schedule[day].times = periodObj.times;
          }
        });
      }
    }
    return day_schedule
  
  
  }

  export function optimize_schedule(daybyday_schedule) {
    const optimized_schedule = {};
  
    for (var day in daybyday_schedule) {
      if (daybyday_schedule.hasOwnProperty(day)) {
        var dayObj = daybyday_schedule[day];
        var times = JSON.stringify(dayObj.times);
  
        if (!optimized_schedule.hasOwnProperty(times)) {
          optimized_schedule[times] = {
            days: [],
            times: dayObj.times
          };
        }
  
        optimized_schedule[times].days.push(day);
      }
    }
  
    const heating_schedule = {};
  
    var index = 1;
    for (var period in optimized_schedule) {
      if (optimized_schedule.hasOwnProperty(period)) {
        var periodObj = optimized_schedule[period];
        heating_schedule["period" + index] = {
          days: periodObj.days,
          times: periodObj.times
        };
        index++;
      }
    }
  
    return heating_schedule;
  }
  



  export const ScheduleDisplay = ({heating_schedule,days=[],current_day,mode,callback=""}) => {
    // example shedule data that holds data for day of week and times indicate the minute into the day and the setpoint value of the thermostat
  let temp_heating_schedule=heating_schedule
   
  
  ////
//[[100 , 18], [800 ,  1], [900 , 19], [1000 ,  1], [1210, 22], [1410,  2]]
    if(current_day!=undefined && days.length==1){
      
      temp_heating_schedule[days[0]].times=current_day
    //  console.log(temp_heating_schedule)
    }

    const fullweek=compute_schedule(temp_heating_schedule)



  
    return (



<div>
            {days.includes("mon") ?(DisplayTimeline("MON",fullweek["mon"],mode,callback)):("")}
            {days.includes("tue") ?(DisplayTimeline("TUE",fullweek["tue"],mode,callback)):("")}
            {days.includes("wed") ?(DisplayTimeline("WED",fullweek["wed"],mode,callback)):("")}
            {days.includes("thu") ?(DisplayTimeline("THU",fullweek["thu"],mode,callback)):("")}
            {days.includes("fri") ?(DisplayTimeline("FRI",fullweek["fri"],mode,callback)):("")}
            {days.includes("sat") ?(DisplayTimeline("SAT",fullweek["sat"],mode,callback)):("")}
            {days.includes("sun") ?(DisplayTimeline("SUN",fullweek["sun"],mode,callback)):("")}

         
            <div className="flex">
              <div className="day_timeline ib"></div>
              <div className="ib fullwidth">
            <table className="cool">
                <tbody>
            <tr id="hours">
              <td style={{"textAlign":"left"}}>00:00</td>
              <td style={{"textAlign":"center"}}>06:00</td>
              <td style={{"textAlign":"center"}}>12:00</td>
              <td style={{"textAlign":"center"}}>18:00</td>
              <td style={{"textAlign":"right"}}>23:59</td>
            </tr>
            </tbody>
          </table>
          </div>
          </div>
      </div>

    );

    /*<tr id="day_display">
{Array(96).fill().map((i)=><td></td>)}
  
</tr>*/
  };





  function compute_schedule(schedule){

    const daymap={
      "mon":"sun","tue":"mon", "wed":"tue","thu":"wed", "fri":"thu", "sat":"fri", "sun":"sat" }


    let days={
      "mon":[],"tue":[],"wed":[],"thu":[],"fri":[],"sat":[],"sun":[]
    }


    for (var period in schedule) {

      if (schedule.hasOwnProperty(period)) {
      //  console.log("Period: " + period);
        var periodObj = schedule[period];
        for (const day of periodObj.days) {
          days[day] = periodObj.times;
        }
      }
    }
    //for (const day of s1.days) {
//      days[day] = s1.times;
  //  }
        
    //for (const day of s2.days) {
      //days[day] = s2.times;
    //}
    
    //we now want to check each day has atleast one entry if it doesnt it should take the last value from the previous valid day.
    for( const day of Object.keys(days)){
      
      
      if(days[day].length==0){
       // console.log(`no data for ${day}`)
        let previous_valid_day=day
        let foundPreviousDay = false;
        //Compute previous days last value
        while(days[previous_valid_day].length==0){
          previous_valid_day=daymap[previous_valid_day]
          if (previous_valid_day === day) {
            // No valid previous day found
            foundPreviousDay = true;
            break;
          }
        }
        if (foundPreviousDay) {
        //  console.log(`No valid previous day found for ${day}`);
          // Set a default value or handle it as needed
          days[day] = [[0, 0]];

        }
        
        
        days[day]=[[0,days[previous_valid_day][days[previous_valid_day].length-1][1]]]
      }
    }
    
    
    //The data should also make sure each day has the value from the last entry of the previous day
    for( const day of Object.keys(days)){
      let previous_day=daymap[day]

      let previous_day_last_temp =days[previous_day][days[previous_day].length-1][1]
      //
      if(days[day][0][0]!=0){
        days[day]=[[0,previous_day_last_temp]].concat(days[day])
      }
    }
  //  console.log(days)
    return(days)
}









function DisplayTimeline(day,times,mode="binary",callback,timeline){

    let percentage_array=[]
    let sum=100
    
  
  
  
  
    if(times.length>1){
    for(let i=1;i<times.length;i++){  
      let width_pc=(((times[i][0]-times[i-1][0])/1440).toPrecision(4)*100).toPrecision(2)
      sum=sum-width_pc
      percentage_array.push([width_pc+"%",times[i-1][1],times[i-1][0]])
    }
    
    let width_pc=(((1440-times[times.length-1][0])/1440).toPrecision(4)*100).toPrecision(2)
    percentage_array.push([sum+"%",times[times.length-1][1],times[times.length-1][0]])
    
    }else{
      percentage_array=[["100%",times[0][1],0]]  
    }
    //calculate the % for each item in thee array
    
    const handleOnClick = (index) => {
     // console.log(index)
      if (callback && typeof callback === "function") {
        callback(index);
      }
    };
    const getCurrentTime = () => {
      const now = new Date();
      const londonTime = now.toLocaleString("en-US", { timeZone: "Europe/London",hour12: false });
      const [_, time] = londonTime.split(", ");
      const [hours, minutes] = time.split(":");
      return parseInt(hours) * 60 + parseInt(minutes);
    };
  
    const getCurrentTimePercentage = () => {
      const currentTime = getCurrentTime();
      
      const totalMinutes = 1440;
      return 100//((currentTime / totalMinutes) * 100).toFixed(2);
    };
  
    const isCurrentDay = () => {
      if(timeline==true){
        return true
      }
      const now = new Date();
      const options = { timeZone: "Europe/London", weekday: "short" };
      const londonDay = now.toLocaleString("en-GB", options);
      return londonDay.toLowerCase().startsWith(day.toLowerCase());
    };
  
    return (
      <div className="flex">
  
  
  
        
      <div className="day_timeline ib">{day}</div>
      <div className="fullwidth timeline_div">
      {isCurrentDay() && false==true? (<div className="current_time_line" style={{ left: `${getCurrentTimePercentage()}%` }}></div>):("")}
      {percentage_array.map((block,block_index)=>{
        if(mode=="binary"){
          return (
            <OverlayTrigger key={day+"_"+block[0]+"_"+block_index} overlay={<Tooltip id={"test"}>{(block[1]===0?("OFF"):"ON")} </Tooltip>}>
          <div  onClick={() => handleOnClick(block[2])} className={"ib "+(block[1]===0?("col_OFF"):"col_ON")}   key={day+"_"+block_index}  style={{width:block[0],height:"25px","textAlign":"center"}}>{block[1]===0?(""):""}</div>
          </OverlayTrigger>
          )
        }
        else{
          return (
            <OverlayTrigger key={day+"_"+block[0]+"_"+block_index} overlay={<Tooltip id={"test"}>{(block[1])}℃</Tooltip>}>
  
          <div onClick={() => handleOnClick(block[2])}  key={day+"_"+block_index} className={`ib timeslot_${block_index} ${block_index === percentage_array.length - 1 ? 'time_slot_last' : ''}`} style={{width:block[0],height:"25px",background:findColorRange(parseInt(block[1])).color,"textAlign":"center","color":"#FFFFFF"}}>{parseFloat(block[0])>=10?(`${block[1]}℃`):(<span>&nbsp;</span>)}</div>
          </OverlayTrigger>
          )
        }
        
        }
      )}
      </div>
      </div>
    )
  
  }
  


export function ScheduleEditor({ daybyday, onSave,mode,label,cancel}) {
    const [schedule, setSchedule] = useState(formatSchedule_human(daybyday["mon"].times));
  
    const [daySchedule,setdayScedule]=useState(daybyday)
  
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isAddingEntry, setIsAddingEntry] = useState(false);
    const [editedDate, setEditedDate] = useState('');
    const [currentEditedDate, setCurrentEditedDate] = useState('');
    const [editedValue, setEditedValue] = useState('');
    const [editingField, setEditingField] = useState('');
    const [currentDay, setCurrentDay] = useState('mon');
    const [copyModalopen, setcopyModalopen] = useState(false);
    const [copyDayArray, setCopyDayArray] = useState([]);
  
    const openModal = (index,editvalue) => {
      setSelectedItemIndex(index);
      
      setEditingField(editvalue);
      setIsAddingEntry(false);
      setEditedDate(schedule[index].date);
      setEditedValue(schedule[index].value);
      setCurrentEditedDate(schedule[index].date)
      setModalIsOpen(true);
    };
  
    const handleDayChange = (event) => {
      const selectedDay = event.target.value;
  
      //current day needs saving!
      const temp_schedule=daySchedule
    //  console.log(temp_schedule[currentDay])
      temp_schedule[currentDay].times=formatSchedule_tomins(schedule)
      setdayScedule(temp_schedule)
  
      setCurrentDay(selectedDay)
      // Call any functions or perform any other actions you need
      // when the current day is changed
      // e.g., update other data, make API calls, etc.
      setSchedule(formatSchedule_human(temp_schedule[selectedDay].times))
      // yourFunctionToUpdateOtherData(selectedDay);
    };
  
    const removeEntry= (index) => {
      const tempschedule=[...schedule]
      tempschedule.splice(index, 1)
      setSchedule(tempschedule);
  
    }
  
    const openAddEntryModal = () => {
      setIsAddingEntry(true);
  
      //check if the time aready exists if it does then add 5 mins until we reach an avaliable slot!
      let inital_time=720
  
      while(schedule.map(item => item.date).includes( mins2hhmm(inital_time))==true){
        inital_time+=5
      }
  
      setEditedDate(mins2hhmm(inital_time));
      setEditingField(''); 
      if(mode=="binary"){
        setEditedValue(1);
      }else{
        setEditedValue(18);
      }
      setCurrentEditedDate('')
      setModalIsOpen(true);
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
      // Reset editing field
    };
  
    const handleDateChange = (e) => {
      setEditedDate(e.target.value);
    };
  
    const handleValueChange = (e) => {
      setEditedValue(e.target.value);
    };
    const handleTempValueChange = (e) => {
      setEditedValue(parseFloat(e).toFixed(0));
    };
  
    const handleStateUpdate=(index,value)=>{
  
      if(index==-1){
       // console.log("temp val")
        setEditedValue(parseFloat(value));
        //Its tempory field value for modal
      }else{
  
      const updatedSchedule = [...schedule];
      updatedSchedule[index].value = value;
      setSchedule(updatedSchedule)
      }
    }
  
  
    const saveEditedValues = () => {
      if (isAddingEntry) {
        const newEntry = { date: editedDate, value: editedValue };
        reorganizeSchedule([...schedule, newEntry]);
      } else {
        const updatedSchedule = [...schedule];
        if (editingField === 'date') {
          updatedSchedule[selectedItemIndex].date = editedDate;
        } else if (editingField === 'value') {
          updatedSchedule[selectedItemIndex].value = parseFloat(editedValue);
        }
        reorganizeSchedule(updatedSchedule);
      }
      closeModal();
      
    };
  
    const reorganizeSchedule = (currentSchedule) => {
      const sortedSchedule = [...currentSchedule];
      sortedSchedule.sort((a, b) => {
        const timeA = getTimeInMinutes(a.date);
        const timeB = getTimeInMinutes(b.date);
        return timeA - timeB;
      });
      setSchedule(sortedSchedule);
    };
  
    const getTimeInMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    };
  
    const handleSave = () => {
      reorganizeSchedule(schedule);
      
    };
  
    const parentSave=()=>{
      onSave(daySchedule);
    }
    
  
    const openCopyModal=()=>{
  
  
      
      setcopyModalopen(true)
    }
  
    const handleCheckboxChange = (day) => {
      if (copyDayArray.includes(day)) {
        // If the day is already in the array, remove it
        setCopyDayArray(copyDayArray.filter((d) => d !== day));
      } else {
        // If the day is not in the array, add it
        setCopyDayArray([...copyDayArray, day]);
      }
    };
  
  
    const handleCopy = () => {
      // Access the selected days in the copyDayArray state
    //  console.log(copyDayArray); // Replace this with your desired logic
  
  
      const temp_schedule=daySchedule
      let times=temp_schedule[currentDay].times
  
      for (const day of copyDayArray) {
  
        temp_schedule[day].times = formatSchedule_tomins(schedule);
      }
  
      setdayScedule(temp_schedule)
  
  
      closeCopyModal()
    };
  
    const closeCopyModal = () => {
      setcopyModalopen(false);
    //  
      // Reset editing field
    };
  
 
    const handleSetWeekdays = (days) => {
      
      setCopyDayArray(days);
    };

    const edit_temp =(valueToFind)=>{
     // console.log("Callback!")
      
      const index = formatSchedule_tomins(schedule).findIndex((innerArray) => innerArray[0] === valueToFind);
      if(index!=-1){
        openModal(index,"value")
      }
      
    }
  
  
    //Check that the user isnt trying to create an entry that conflicts with an existing one.
    const duplicateTimes = (schedule.map(item => item.date).includes(editedDate) && currentEditedDate!==editedDate) && modalIsOpen;
  
  
    return (
      <div key={"schedule"}>
  
  
  <Card >
  
  
  <Card.Header>
        <Row>
          <Col>
        <Form.Select value={currentDay} onChange={handleDayChange}>
          <option value="mon">Monday</option>
          <option value="tue">Tuesday</option>
          <option value="wed">Wednesday</option>
          <option value="thu">Thursday</option>
          <option value="fri">Friday</option>
          <option value="sat">Saturday</option>
          <option value="sun">Sunday</option>
        </Form.Select>
        </Col>
        <Col>
        <Button variant="outlined" onClick={()=>openCopyModal()}>
        <IoDuplicateOutline style={{marginBottom:"5px"}}/>{' '}COPY
        </Button></Col>
        </Row> 
        </Card.Header>
  <Card.Header>
    
  <ScheduleDisplay  heating_schedule={daySchedule} days={[currentDay]} mode={mode} current_day={formatSchedule_tomins(schedule)} callback={edit_temp}></ScheduleDisplay>
  
  
    <Row >
      <Col >Time</Col ><Col >{label}</Col > <Col ></Col > </Row>
    
      
  
    
    </Card.Header>
          <ListGroup variant="flush">
       
            {schedule.map((item, index) => (
               <ListGroup.Item key={index}>    
                
                <div className="flex flexbetween">
                 <Col className="clickable hoverblue" onClick={() => openModal(index,"date")}>{item.date} <MdOutlineModeEditOutline className="grey"/></Col>              
                 {mode=="binary" ? (
                 <Col className="clickable hoverblue" >
                 <OnOFFToggle key={"TOG"+index} index={index} state={item.value}  onChangeCallback={handleStateUpdate}/>
                  </Col>
                  ):(<Col onClick={() => openModal(index,"value")}>
  <span>{item.value}℃</span>
  </Col>
                  )}
                  
  
        
  
  
  
                 <Col className="clickable hoverred" onClick={() => removeEntry(index)}><MdOutlineDeleteOutline/></Col>
             </div>
             
             </ListGroup.Item>
            ))}
              </ListGroup>
              <br></br>
              <ListGroup.Item>    
              <Button variant="outline-primary" onClick={cancel}>Cancel</Button>{' '}
              <Button  variant="outline-primary" onClick={openAddEntryModal}><MdAddCircleOutline/> New Entry</Button>{' '}
        <Button  variant="outline-primary" onClick={parentSave}>Save</Button>
        
        </ListGroup.Item>
        <br></br>
              </Card>
  
    
    
    
  
  
        <Modal show={modalIsOpen}  onHide={closeModal}> 
        <Modal.Header closeButton>
          {isAddingEntry ? 'Add New Entry' : 'Edit Values'}
          </Modal.Header>
          <Modal.Body>
            {editingField==='date'||editingField==='' ?(<React.Fragment>
            <Form.Label htmlFor="timeinput">Time</Form.Label>
            <Form.Control type="time" id="timeinput" placeholder="Time of change"     onChange={handleDateChange}   value={editedDate}/>
            {duplicateTimes===true?(
                <>
                <br></br>
                <Alert key={"danger"} variant="danger">
                Time duplicate {editedDate} already exists
                </Alert>
                </>
            ):("")}
            </React.Fragment>):("")}         
               
            {editingField==='value'||editingField==='' ?(
                <React.Fragment>
                  <br></br>
          <Form.Label htmlFor="tempinput">{label}</Form.Label>
                  <br></br>
              {mode=="binary" ? (<OnOFFToggle key={"TOG"+"X"} index={-1} state={editedValue}  onChangeCallback={handleStateUpdate}/>):(
          <center><TemperatureInput minValue={0} maxValue={30} onValueChange={handleTempValueChange} initalValue={editedValue}/>  </center>)}
            </React.Fragment>
  ):("")}
          </Modal.Body>
  
  
          <Modal.Footer>
          <Button onClick={saveEditedValues} disabled={duplicateTimes===true}>{isAddingEntry ? 'Add' : 'Save'}</Button>
          <Button onClick={closeModal}>Cancel</Button>
          </Modal.Footer>
        </Modal>
  
        <Modal show={copyModalopen} onHide={closeCopyModal}> 
        <Modal.Header closeButton>
          Copy day to
          </Modal.Header>
          <Modal.Body>

          <Button variant="outline-primary" onClick={()=>handleSetWeekdays(['mon', 'tue', 'wed', 'thu', 'fri','sat','sun'])}>
      Select All
    </Button>{' '}

          <Button variant="outline-primary" onClick={()=>handleSetWeekdays(['mon', 'tue', 'wed', 'thu', 'fri'])}>
      Select Mon-Fri
    </Button>{' '}<Button variant="outline-primary" onClick={()=>handleSetWeekdays(['sat','sun'])}>
      Select Sat-Sun
    </Button>{' '}
    <Button variant="outline-primary" onClick={()=>handleSetWeekdays([])}>
      Clear
    </Button>{' '}


          {[{ id: 'monday', label: 'Monday', value: 'mon' },
    { id: 'tuesday', label: 'Tuesday', value: 'tue' },
    { id: 'wednesday', label: 'Wednesday', value: 'wed' },
    { id: 'thursday', label: 'Thursday', value: 'thu' },
    { id: 'friday', label: 'Friday', value: 'fri' },
    { id: 'saturday', label: 'Saturday', value: 'sat' },
    { id: 'sunday', label: 'Sunday', value: 'sun' }].map((day) => (
      <Row>
      <Col>
      <Form.Check
        key={day.id}
        type="checkbox"
        id={`${day.id}-checkbox`}
        label={day.label}
        value={day.value}
        checked={copyDayArray.includes(day.value)}
        disabled={currentDay === day.value}
        onChange={() => handleCheckboxChange(day.value)}
      />
       </Col>
       <Col>
      { (JSON.stringify(daySchedule[day.value].times)==JSON.stringify(daySchedule[currentDay].times) && (day.value!==currentDay))?(<><FaCheck/>{' '}same timing</>):("")}
      </Col>
      </Row>
    ))}
  
  
  
  
  
  
  
  
          </Modal.Body>
  
  
          <Modal.Footer>
          <Button onClick={()=>handleCopy()}>Copy</Button>
          <Button onClick={()=>closeCopyModal()} >Cancel</Button>
          </Modal.Footer>
        </Modal>
  
      </div>
    );
    
  
  }
  




import React, { Component,useRef ,useState,useEffect} from "react";

 
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { FaHandHoldingWater,FaTemperatureHigh, } from "react-icons/fa";
import { GiTowel } from "react-icons/gi";
import { MdSevereCold,MdChevronRight,MdOutlineAccessTime } from "react-icons/md";
import {Controls,ScheduleEditor,ScheduleDisplay,daybyday_schedule,optimize_schedule,AssigntoMany} from "../Schedule/schedule_control.js"
import Form from 'react-bootstrap/Form';


export default class Heating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view:"home",
      water_mode:"on",
      water_state:"on",
      edit:false,
      current_schedule:"",
      show_shedule:false,
      schedule_assign:false,
      new_auto_assign:"",
      new:false,
      currentSchedule:{}
    }
  }
  assignSchedule=()=>{
    this.setState({schedule_assign:true})
  }
  reAssignSchedule=(zone_update_list, schedule_id)=>{
    this.setState({schedule_assign:false})
  }

  edit_schedule = (name,id) => {

    let new_state=name=="new"

    let edit_schedule={
      "period1": {
          "days": ["mon","tue","wed","thu","fri"],
          "times": [[420,1],[540,0],[960,1],[1320,0]]
      },
      "period2": {
          "days": ["sat","sun"],
          "times": [[420,1],[1320,0]]
      }
    }

    if(name in this.props.schedules){
      //We have shcedules
     
      edit_schedule=this.props.schedules[name]
    }else{
      name=""
    }
      




    this.setState({
      schedule_assign:false,
      current_schedule: name,
      show_shedule: true,
      new:new_state,
      new_auto_assign:id,
      edit:new_state,
      currentSchedule:edit_schedule
    }, () => {
      // After setting the state to show the schedule, scroll to the element
      const element = document.getElementById("edit_schedule");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    });
  };


  changeHeatingValue = (zones, value,mode) => {
    console.log("Setpoint callback")
    let updatemode="mode"

    if(zones.length==undefined){
      
      zones=[zones]
    }
    /*let updatedZoneMap = this.state.zoneMap.map((zone) => {
      if (zones.includes(zone.id)) {
        
        if (typeof value === 'number') {
          updatemode="setpoint"
          return { ...zone, setpoint: parseInt(value), mode:mode }; // Update the setpoint field when value is a number
        } else {
          return { ...zone, mode: value }; // Otherwise, update the mode field
        }
      }
      return zone;
    });*/

    
    if(typeof value === 'number'){
      console.log("should update all zones")
      console.log(zones)
      //its a setupoint update
     
      
      let mode_msg={
        cmd:"vars",
        type:"updateval_array",
        items:zones,
        value:mode,
        var:"mode"

      }
      this.props.message_callback(mode_msg)
      let setpoint_msg={
        cmd:"vars",
        type:"updateval_array",
        items:zones,
        value:value,
        var:"setpoint"
      }
      
      this.props.message_callback(setpoint_msg)
    }
    else{
     
      let mode_msg={
        cmd:"vars",
        type:"updateval_array",
        items:zones,
        value:value,
        var:"mode",
        check_schedule:true
      }
      
      this.props.message_callback(mode_msg)

    }





    
  
    
  
  
  };
  handleSave=(schedule)=>{
    this.setState({currentSchedule:optimize_schedule(schedule)})



    if(this.state.new==true){
      if(this.state.current_schedule=="new"||this.state.current_schedule==""){
        alert("Schedule cannot be called new or blank")
        return 
      }
      if(this.props.current_schedules.includes(this.state.current_schedule)==true){
        alert("Shedule with same name already exists!")
        return 
      }
    }
    
    let d={
      cmd:"HEATING",
      type:"setSchedule",
      data:optimize_schedule(schedule),
      schedule:this.state.current_schedule
    }
    this.props.message_callback(d)
    if(this.state.new==true){
      this.props.message_callback({cmd:"schedule_assign",zones:[this.state.new_auto_assign],schedule:this.state.current_schedule})
      this.setState({new:false})
    }
    //need a list of schedules to check against


    console.log(optimize_schedule(schedule))
    this.setState({edit:false})
  }
  handleEditCancel=()=>{
    if (this.state.new==true){
this.setState({show_shedule:false})
}
this.setState({edit:false})
}


clear_schedule=(id,schedule)=>{
  this.setState({show_shedule:false})
  let d={cmd:"schedule_unassign",zone:id,schedule:schedule}
  this.props.message_callback(d)
}

reAssignSchedule=(zone_update_list, schedule_id)=>{
  let d={cmd:"schedule_assign",zones:zone_update_list,schedule:schedule_id}
  this.props.message_callback(d)
  this.setState({schedule_assign:false})
}

  render(){

    const zoneList = this.props.data.map(zone => {
      const { id, name,schedule } = zone;
      return { id, name ,schedule};
    });
  return ( <Container>
     <center>
           <Card style={{ maxWidth: '30rem' }}>   
            {this.props.data.length>1 ?(<React.Fragment>
            <Card.Body>
                <Controls
                key={"zone_control"}
                zoneList={zoneList}
                id="bathroom1"
                setPoint={true}
                state={this.props.state}
            stateText="Control Multi"
            modeText="Heating mode"
            currentState={"n/a"}
            currentMode={"n/a"}
            onValueChange={this.changeHeatingValue}
          /></Card.Body>
          <hr/>
          </React.Fragment>):("")}
    
          {this.props.data.map((zone, zone_index)=>
    
                <Card.Body>
                <Controls
                zone={[zone.id]}
                id={zone.id}
                zonedata={zone}
                vars={this.props.vars}
                setPoint={zone.setpoint}
                state={this.props.state}
            stateText={zone.name}
            modeText="Heating mode"
            currentState={zone.state?("On"):("Off")}
            currentMode={zone.mode}
            onValueChange={this.changeHeatingValue}
          />
              {zone.schedule==""?(<Badge onClick={()=>this.edit_schedule("new",zone.id)}><MdOutlineAccessTime />Create new shedule</Badge>):
              (<>
              
              <Badge onClick={()=>this.edit_schedule(zone.schedule)}><MdOutlineAccessTime /> View {zone.schedule}</Badge>
              <Badge  bg="secondary" onClick={()=>this.clear_schedule(zone.id,zone.schedule)}><MdOutlineAccessTime /> Remove {zone.schedule}</Badge>
              
              </>)}
          </Card.Body>)}
          
          </Card>
          </center>
    <br/>
    
          <center id="edit_schedule">
          {this.state.show_shedule==true ?(<Card style={{ maxWidth: '30rem' }}>
                <Card.Body>
                <Card.Title>
               {this.state.new==true ?(
                      <Form.Control
                      type="text"
                      placeholder="Add Schedule Name"
                      value={this.state.current_schedule}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        // Remove characters that are not allowed in JSON object keys
                        const sanitizedValue = inputValue
                          .replaceAll(" ", "_") // Replace spaces with underscores
                          .replace(/[^a-zA-Z0-9_-]/g, ""); // Remove any characters other than letters, digits, underscore, and hyphen
                    
                        this.setState({ current_schedule: sanitizedValue });
                      }}
                    />
                ):(this.state.current_schedule)}     
            </Card.Title>
            <Card.Subtitle>
            <p>  <Button variant="outline-secondary" onClick={()=>this.setState({show_shedule:false})} >Close</Button></p>
            </Card.Subtitle>
                
                {this.state.edit==false ?(
                <Card.Text>
                  <ScheduleDisplay heating_schedule={this.state.currentSchedule} days={["mon","tue","wed","thu","fri","sat","sun"]} mode="" />
                  <Button variant="outline-primary" onClick={()=>{this.setState({edit:true})}}>Edit Schedule</Button>
    
                  {' '}
                  <Button variant="outline-primary" onClick={()=>{this.assignSchedule()}} >Apply to zones</Button>
                  <hr></hr>
                  <p>Applied to:</p>
                  {zoneList.filter(item => item.schedule === this.state.current_schedule).map(item => item.name).map((item,index)=>
                  <><Badge bg="light" text="primary" >{item}</Badge>{' '}</>
                  )}
                </Card.Text>
                ):(
                <Card.Text>
                  <ScheduleEditor 
                      daybyday={daybyday_schedule(this.state.currentSchedule)}
                      onSave={this.handleSave}
                      cancel={this.handleEditCancel}
                      mode=""
                      label="Temperature"
                   />
                </Card.Text>)}
    
                </Card.Body>
          </Card>):("")}
          </center>
          <AssigntoMany key={this.state.current_schedule+"_"+this.state.schedule_assign} 
          schedule_id={this.state.current_schedule}
           open={this.state.schedule_assign} 
           title={"Assign to "+this.state.current_schedule} 
           onValueChange={this.reAssignSchedule}
            zoneList={zoneList} ></AssigntoMany>
    
    
  
      
            </Container>)
  }
}




 
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { FaHandHoldingWater,FaTemperatureHigh, } from "react-icons/fa";
import { GiTowel } from "react-icons/gi";
import { MdSevereCold,MdChevronRight } from "react-icons/md";

export default function HomeMenu(props){


    return ( <Container>
        <Card onClick={()=>props.callback("Hot water")} className ="top_menu_card" style={{"marginTop":"8px"}}>
          <Card.Body>
            <Card.Title><FaHandHoldingWater/>&nbsp;Hot Water</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">alter hot water schedule</Card.Subtitle>
            <Card.Text>
            {/*<Badge pill bg="danger">
          Hot water is on
    </Badge>{' '}*/}
          </Card.Text>
          </Card.Body>
          <Button  className="top_menu_button"><MdChevronRight/></Button>
        </Card>
  
        <Card onClick={()=>props.callback("Towel rails")} className="top_menu_card">
          <Card.Body>
            <Card.Title><GiTowel/>&nbsp;Towel Rails</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">control towel rails</Card.Subtitle>
            <Card.Text>
            {/*<Badge pill bg="info">
            1/6 Towel rails are on
    </Badge>{' '}*/}
            </Card.Text>
          </Card.Body>
          <Button  className="top_menu_button"><MdChevronRight/></Button>
        </Card>
  
        <Card onClick={()=>props.callback("Heating")} className="top_menu_card">
          <Card.Body>
            <Card.Title><FaTemperatureHigh/>&nbsp;Heating</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">control and shedule heating zones</Card.Subtitle>
            <Card.Text>
            {/*<Badge pill bg="info">
            Heating is off
  </Badge>{' '}*/}
            </Card.Text>
            
          </Card.Body>
          <Button  className="top_menu_button"><MdChevronRight/></Button>
        </Card>
  
        <Card onClick={()=>props.callback("Cooling")} className="top_menu_card">
          <Card.Body>
            <Card.Title><MdSevereCold/>&nbsp;Cooling</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">control cooling zones</Card.Subtitle>
            <Card.Text>
            {/*<Badge pill bg="info">
            Cooling is disabled
</Badge>{' '}*/}
            </Card.Text>
          </Card.Body>
          <Button  className="top_menu_button"><MdChevronRight/></Button>
        </Card>
  
  <i style={{color:"#c3c3c3"}}>version 18.08.2023.1117</i>
  
        </Container>)
}
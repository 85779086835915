import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Amplify } from 'aws-amplify';


import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';


import config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "AltiomData",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

Amplify.addPluggable(new AWSIoTProvider({
     aws_pubsub_region: 'eu-west-1',
     aws_pubsub_endpoint: 'wss://a3duuhb6j1ul52-ats.iot.eu-west-1.amazonaws.com/mqtt',
   }));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
  
);

/*
<React.StrictMode>
    <App />
  </React.StrictMode>
  */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

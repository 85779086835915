
import React, { Component,useRef ,useState,useEffect} from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

export function OnOFFToggle({state,index,onChangeCallback}){
  
    const [radioValue, setRadioValue] = useState(state);
  
  return(<ButtonGroup>
          <ToggleButton
              key={"radio-ON"+index}
              id={"radio-ON"+index}
              type="radio"
              size="sm"
              variant={ 'outline-danger'}
              name={"radioCtrl"+index}
              value={1}
              checked={state === 1}
              onChange={(e) => {
                onChangeCallback(index,parseFloat(e.currentTarget.value))
                
              }
            }
            >
              {"ON"}
            </ToggleButton>
            <ToggleButton
              key={"radio-OFF"+index}
              id={"radio-OFF"+index}
              type="radio"
              size="sm"
              variant={ 'outline-secondary'}
              name={"radioCtrl"+index}
              value={0}
              checked={state === 0}
              onChange={(e) => {
                onChangeCallback(index,parseFloat(e.currentTarget.value))
                 
              }
              }
            >
              {"OFF"}
            </ToggleButton>
  
  </ButtonGroup>)
  
  }
  
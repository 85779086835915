
import React, { Component,useRef ,useState,useEffect} from "react";

 
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { FaHandHoldingWater,FaTemperatureHigh, } from "react-icons/fa";
import { GiTowel } from "react-icons/gi";
import { MdSevereCold,MdChevronRight,MdOutlineAccessTime } from "react-icons/md";
import {ControlsCool,ScheduleEditor,ScheduleDisplay,daybyday_schedule,optimize_schedule,AssigntoMany} from "../Schedule/schedule_control.js"
import Form from 'react-bootstrap/Form';


export default class Cooling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view:"home",
      edit:false,
      current_schedule:"",
      show_shedule:false,
      schedule_assign:false,
      new_auto_assign:"",
      new:false,
      currentSchedule:{}
    }
  }
  assignSchedule=()=>{
    this.setState({schedule_assign:true})
  }
  reAssignSchedule=(zone_update_list, schedule_id)=>{
    this.setState({schedule_assign:false})
  }

  edit_schedule = (name,id) => {

    let new_state=name=="new"

    let edit_schedule={
      "period1": {
          "days": ["mon","tue","wed","thu","fri"],
          "times": [[420,1],[540,0],[960,1],[1320,0]]
      },
      "period2": {
          "days": ["sat","sun"],
          "times": [[420,1],[1320,0]]
      }
    }

    if(name in this.props.schedules){
      //We have shcedules
     
      edit_schedule=this.props.schedules[name]
    }
    




    this.setState({
      schedule_assign:false,
      current_schedule: name,
      show_shedule: true,
      new:new_state,
      new_auto_assign:id,
      edit:new_state,
      currentSchedule:edit_schedule
    }, () => {
      // After setting the state to show the schedule, scroll to the element
      const element = document.getElementById("edit_schedule");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }
    });
  };


  changeCoolingValue = (zones, value,mode) => {
    console.log("Setpoint callback")
    let updatemode="mode"

    if(zones.length==undefined){
      zones=[zones]
    }
    /*let updatedZoneMap = this.state.zoneMap.map((zone) => {
      if (zones.includes(zone.id)) {
        
        if (typeof value === 'number') {
          updatemode="setpoint"
          return { ...zone, setpoint: parseInt(value), mode:mode }; // Update the setpoint field when value is a number
        } else {
          return { ...zone, mode: value }; // Otherwise, update the mode field
        }
      }
      return zone;
    });*/

    
    if(typeof value === 'number'){
      console.log("should update all zones")
      console.log(zones)
      //its a setupoint update
      let setpoint_msg={
        cmd:"vars",
        type:"updateval_array",
        items:zones,
        value:value,
        var:"setpoint",
        cooling:true
      }
      this.props.message_callback(setpoint_msg)
      let mode_msg={
        cmd:"vars",
        type:"updateval_array",
        items:zones,
        value:mode,
        var:"mode",
        cooling:true
      }
      this.props.message_callback(mode_msg)
    }
    else{
      
      let fan_mode=["fan1","fan2","fan3"].includes(value)
      let opperating_mode=["heat","cool","fan","dry"].includes(value)
      let var_name="mode"
      
      if(fan_mode==true){
        var_name="fan"
      }
      if(opperating_mode==true){
        var_name="opmode"
      }

      


      let mode_msg={
        cmd:"vars",
        type:"updateval_array",
        items:zones,
        value:value,
        var:var_name,
        cooling:true,
      }
      
      
      this.props.message_callback(mode_msg)

    }





    
  
    
  
  
  };
  handleSave=(schedule)=>{
    this.setState({currentSchedule:optimize_schedule(schedule)})



    if(this.state.new==true){
      if(this.state.current_schedule=="new"){
        alert("Schedule cannot be called new")
        return 
      }
      if(this.props.current_schedules.includes(this.state.current_schedule)==true){
        alert("Shedule with same name already exists!")
        return 
      }
    }
    
    let d={
      cmd:"HEATING",
      type:"setSchedule",
      data:optimize_schedule(schedule),
      schedule:this.state.current_schedule
    }
    this.props.message_callback(d)
    if(this.state.new==true){
      this.props.message_callback({cmd:"schedule_assign",zones:[this.state.new_auto_assign],schedule:this.state.current_schedule})
      this.setState({new:false})
    }
    //need a list of schedules to check against


    console.log(optimize_schedule(schedule))
    this.setState({edit:false})
  }
  handleEditCancel=()=>{
    if (this.state.new==true){
this.setState({show_shedule:false})
}
this.setState({edit:false})
}


clear_schedule=(id,schedule)=>{
  this.setState({show_shedule:false})
  let d={cmd:"schedule_unassign",zone:id,schedule:schedule}
  this.props.message_callback(d)
}

reAssignSchedule=(zone_update_list, schedule_id)=>{
  let d={cmd:"schedule_assign",zones:zone_update_list,schedule:schedule_id}
  this.props.message_callback(d)
  this.setState({schedule_assign:false})
}

changeFanspeed=(zone, value)=>{
  alert(zone,value)
}

  render(){

    const zoneList = this.props.data.map(zone => {
      const { id, name,schedule } = zone;
      return { id, name ,schedule};
    });
  return ( <Container>
     <center>
           <Card style={{ maxWidth: '30rem' }}>   
            {this.props.data.length>1 ?(<React.Fragment>
            <Card.Body>
                <ControlsCool
                key={"zone_control"}
                zoneList={zoneList}
                id="bathroom1"
                mode="cooling"
                setPoint={true}
            stateText="Control Multi"
            vars={this.props.vars}
            state={this.props.state}
            modeText="Cooling mode"
            currentState={"n/a"}
            currentMode={"n/a"}
            onFanChange={this.changeFanspeed}
            onValueChange={this.changeCoolingValue}
          /></Card.Body>
          <hr/>
          </React.Fragment>):("")}
    
          {this.props.data.map((zone, zone_index)=>
    
                <Card.Body>
                <ControlsCool
                zone={[zone.id]}
                id={zone.id}
                zonedata={zone}
                setPoint={zone.setpoint}
                state={this.props.state}
            stateText={zone.name}
            vars={this.props.vars}
            modeText="Cooling mode"
            currentState={zone.state}
            currentMode={zone.mode}
            onFanChange={this.changeFanspeed}
            onValueChange={this.changeCoolingValue}
          />
     
          </Card.Body>)}
          
          </Card>
          </center>
    <br/>
    

          
    
    
  
      
            </Container>)
  }
}

